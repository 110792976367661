import React from 'react';
import { Link } from "gatsby";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";
import FontIcon from "react-md/lib/FontIcons/FontIcon";

import Layout from '../../layout';

const Information = () => (
  <Layout>
    <Grid stacked>
      <Cell
        align='middle'
        desktopSize={8}
        tabletSize={6}
      >
        <Card>
          <CardTitle title="Admissions" />
          <CardText>
            <h4 className="md-subheading-2">Welcome to the Mindful Montessori Early Learning Center!</h4>
            <p className="md-text-justify">
              We look forward to introducing you to our vibrant community.
              We invite you to explore our website and to read the array of articles
              and postings found throughout the About Us and Program sections.
            </p>
            <p className="md-text-justify">
              We host Observation Mornings for prospective parents on a weekly basis during the school year.
              In the summer, there is ample opportunity to meet with staff and tour our facility.
              These visits offer an overview of Montessori philosophy, an opportunity to
              observe in a classroom (when school is in session) and gather for a Q&A. Please
              &nbsp;
              <Link to="/about/contact">contact us</Link>
              &nbsp;
              or email
              &nbsp;
              <FontIcon primary forceSize iconClassName="fa fa-envelope" />
              &nbsp;
              <a href="mailto:admissions@mindfulmontessori.com">admissions@mindfulmontessori.com</a>
              &nbsp;
              to schedule a visit.
              Parent Information Sessions are an additional way to learn about our school
              through interactions with our staff, current parents and students.
              We host a comprehensive collection of Parent Education events, classes and
              workshops throughout the year, many of which are open to prospective parents.
            </p>
            <p className="md-text-justify">
              It is often said that Montessori is more than an approach to education -- it is a way of life.
              We are delighted by your interest in our school and look forward to coming
              to know you along the way of our admission process.
            </p>
          </CardText>
        </Card>
      </Cell>
    </Grid>
  </Layout>
)

export default Information;
